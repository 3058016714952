<template>
	<div id="content" data-app>
		<CargarEdificios />
	</div>
</template>
<script>
	import CargarEdificios from '@/components/CargarEdificios.vue';

	export default {
		components: {
			CargarEdificios
		}
	}
</script>