<template>
	<div id="content" data-app>
		<Edificios />
	</div>
</template>
<script>
	import Edificios from '@/components/Edificios.vue';

	export default {
		components: {
			Edificios
		}
	}
</script>