<template>
  <div id="main" class="container">
    <div class="row">
      <header class="col-sm-12">
        <Menu token="token" />
      </header>
    </div>
    <div class="row">
      <!--<div id="sidebar" class="col-sm-3">-->
        <Sidebar v-if="token" />
      <!--</div>-->
      <div :class="contentClass">
        <router-view />
      </div>
    </div>
    <Footer />
    <Loader v-if="loader"/>
  </div>
</template>
<script>
  import Sidebar from '@/components/Sidebar.vue'
  import Menu from '@/components/Menu.vue'
  import Footer from '@/components/Footer.vue'
  import Loader from '@/components/Loader.vue'
  import { mapState } from "vuex";
  import store from './store'
  //import { mapActions } from "vuex";

  //localStorage.loader = false;
  export default{
    name: 'App',
    components: {
      Sidebar, Menu, Footer, Loader
    },
    data(){
      return {
        contentClass: 'container',
        token: store.getters.isAuthenticated
        //loader: true
      }
    },
    mounted(){
      if(this.token) {
        this.contentClass= 'container col-sm-9';
      } else {
        this.contentClass= 'container col-sm-12 text-center';
      }
      this.contentClass= 'container col-sm-12 text-center';
      //const variable = this.token;
      //console.log('Appvue, Variable: ' + variable);
      /*if(!this.dbGetToken()) {
        this.contentClass = 'col-sm-12 container';
      } else {
        this.contentClass = 'col-sm-9 container';
      }
      //this.$emit('toggleLoader', true);
      //this.loader = this.$emit('getLoader');
      */
    },
    /*methods: {
      ...mapActions({
        token: 'query' // map `this.add()` to `this.$store.dispatch('increment')`
      })
    },*/
    computed: {
      //variable: this.$store.actions.query,
      //console.log('Appvue, Variable: ' + variable);
      ...mapState([
        'loader'
      ]),
    }
  }
</script>