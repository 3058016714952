export default {
	SET_TOKEN(state, payload){
		state.llave = payload;
		localStorage.token = payload;
		return payload;
	},
	setUuid(state, payload){
		state.uuid = payload;
	},
	toggleLoader(state, payload){
		if(payload) {
			state.loader = !state.loader;
		} else {state.loader = payload;}
	},
	getLlave: state => state.llave
}