<template>
	<div class="content">
		<AddReporteForm/>
	</div>
</template>

<script>
	import AddReporteForm from '@/components/AddReportes.vue'

	export default {
		name: 'AddReporte',
		components: {
			AddReporteForm
		}
	}
</script>