import axios from "axios";

export default {
	setToken(state, payload){
		state.commit('SET_TOKEN', payload);
	},
	async postLogin({dispatch}, payload){
		let serverUrl = await dispatch('getUrl');
		let respuesta = [];
		respuesta.errors = [];
		console.log('Login email: ' + payload.email);
		console.log('Login pass: ' + payload.password);
		console.log('Login crate token: ' + payload.createToken);

		/*await axios({
			method: 'get',
			url: serverUrl.replace('api/', '') + 'sanctum/csrf-cookie',
			data: payload,
			withCredentials: false,
		}).then(response => { 
			console.log(response);
			}).catch(error => {
				respuesta.error = error;
				console.log(respuesta);
		});*/
		await axios({
					method: 'post',
					url: serverUrl + 'login',
					data: payload,
					headers: {
						'Content-Type': 'application/json',
						'csrf-token': 'content',
					}
		}).then(response => {
			if(response.data.token &&  response.data.token != null) { 
				respuesta.token = response.data.token.split("|").pop();
				respuesta.message = 'Autenticado, redireccionando...';
			} else if (response.data) {
				respuesta['error'] = response.data.message;
			}
		}).catch(error => {
			console.log(error);
			if (error.data) {
				respuesta['error'] = error.data.message;
			} else if (error.response) {
				respuesta['error'] = error.response;
			} else if (error.message) {
				respuesta['error'] = error.message;
			} else {
				respuesta['error'] = error;
			}
		});
		return dispatch('commitLogin', respuesta);
	},
	commitLogin({commit}, respuesta){
		if(respuesta.token) {
			commit('SET_TOKEN', respuesta.token);
			//console.log(respuesta.token);
			return respuesta;
		} else {
			commit('SET_TOKEN', '');
			return respuesta;
		}
	},
	async getReportes({commit, dispatch}, payload){
		let serverUrl = await dispatch('getUrl');
		let respuesta = [];
		respuesta.errors = [];
		//await axios.get(serverUrl.replace('api/', '') + 'sanctum/csrf-cookie');
		await axios({
					method: 'get',
					url: serverUrl + 'reportes',
					data: payload,
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer '+ localStorage.token
					}
		}).then(response => {
			respuesta =  response;
		}).catch(error => {
			respuesta.error = error;
			if(error.response.status == 401) {
				commit('SET_TOKEN', '');
			}
			console.log(error.response);
			console.log(respuesta);
		});
		return respuesta;
	},
	async getReporte({commit, dispatch}, payload){
		//console.log(payload);
		let serverUrl = await dispatch('getUrl');
		let respuesta = [];
		respuesta.errors = [];

		//await axios.get(serverUrl.replace('api/', '') + 'sanctum/csrf-cookie');
		await axios({
					method: 'get',
					url: serverUrl + 'reportes/' + payload,
					data: payload,
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer '+ localStorage.token
					}
		}).then(response => {
			console.log(response.data);
			respuesta =  response.data;
		}).catch(error => {
			console.log(error);
			//respuesta.error = error;
			if(error.response.status == 401) {
				commit('SET_TOKEN', '');
			}
			console.log(error.response);
			console.log(respuesta);
		});
		return respuesta;
	},
	async editReporte({dispatch}, payload){
		let serverUrl = await dispatch('getUrl');
		let respuesta = [];
		let bindedUrl = '';
		let metodo = '';
		respuesta.errors = [];
		//console.log(payload);

		if(payload.id != null && payload.id) {
			bindedUrl = 'reportes/' + payload.id,
			metodo = 'put'
		} else {
			bindedUrl = 'reportes',
			metodo = 'post'
			//console.log(typeof(payload));
			delete payload.id;
		}
		//await axios.get(serverUrl.replace('api/', '') + 'sanctum/csrf-cookie');
		await axios({
					method: metodo,
					url: serverUrl + bindedUrl,
					data: payload,
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer '+ localStorage.token
					}
		}).then(response => {
			respuesta =  response;
			console.log(respuesta);
		}).catch(error => {
			respuesta.error = error;
			console.log(respuesta);
		});
		return respuesta;
	},
	async deleteReporte({dispatch}, payload){
		let serverUrl = await dispatch('getUrl');
		let respuesta = [];
		let bindedUrl = '';
		respuesta.errors = [];

		bindedUrl = 'reportes/' + payload
		//await axios.get(serverUrl.replace('api/', '') + 'sanctum/csrf-cookie');
		await axios({
					method: 'delete',
					url: serverUrl + bindedUrl,
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer '+ localStorage.token
					}
		}).then(response => {
			respuesta =  response;
			console.log(respuesta);
		}).catch(error => {
			respuesta.error = error;
			console.log(respuesta);
		});
		return respuesta;
	},
	async getReportesBancarios({dispatch}){
		let serverUrl = await dispatch('getUrl');
		let respuesta = [];
		respuesta.errors = [];
		//await axios.get(serverUrl.replace('api/', '') + 'sanctum/csrf-cookie');
		await axios({
					method: 'get',
					url: serverUrl + 'reporte_bancario',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer '+ localStorage.token
					}
		}).then(response => {
			respuesta =  response;
		}).catch(error => {
			respuesta.error = error;
			if(error.response.status == 401) {
				dispatch('Logout');
			}
			if(error.response.data){
				return error.response.data;
			}
			console.log(error.response);
		});
		return respuesta;
	},
	async subirReporteBancario({dispatch}, payload){
		let serverUrl = await dispatch('getUrl');
		let respuesta = [];
		respuesta.errors = [];
		//await axios.get(serverUrl + 'sanctum/csrf-cookie');
		await axios({
					method: 'post',
					url: serverUrl + 'reporte_bancario',
					data: payload,
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer '+ localStorage.token
					}
		}).then(response => {
			respuesta =  response;
		}).catch(error => {
			respuesta.error = error;
			console.log(respuesta);
		});
		return respuesta;
	},
	async getConciliados({dispatch}, payload){
		let serverUrl = await dispatch('getUrl');
		let respuesta = [];
		respuesta.errors = [];
		//await axios.get(serverUrl.replace('api/', '') + 'sanctum/csrf-cookie');
		await axios({
					method: 'get',
					url: serverUrl + 'conciliacion',
					data: payload,
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer '+ localStorage.token
					}
		}).then(response => {
			respuesta =  response;
		}).catch(error => {
			respuesta.error = error;
			console.log(respuesta);
		});
		return respuesta;
	},
	async getPosiblesConciliados({dispatch}, payload){
		let serverUrl = await dispatch('getUrl');
		let respuesta = [];
		respuesta.errors = [];
		console.log('posibles conciliados');
		//await axios.get(serverUrl.replace('api/', '') + 'sanctum/csrf-cookie');
		await axios({
					method: 'get',
					url: serverUrl + 'posible-conciliacion',
					data: payload,
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer '+ localStorage.token
					}
		}).then(response => {
			respuesta =  response;
		}).catch(error => {
			respuesta.error = error;
			console.log(respuesta);
		});
		return respuesta;
	},
	async getEdificios({commit, dispatch}, payload){
		let serverUrl = await dispatch('getUrl');
		let respuesta = [];
		respuesta.errors = [];
		//await axios.get(serverUrl.replace('api/', '') + 'sanctum/csrf-cookie');
		await axios({
					method: 'get',
					url: serverUrl + 'edificios',
					data: payload,
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer '+ localStorage.token
					}
		}).then(response => {
			respuesta =  response;
		}).catch(error => {
			respuesta.error = error;
			if(error.response.status == 401) {
				commit('SET_TOKEN', '');
			}
			console.log(error.response);
			console.log(respuesta);
		});
		return respuesta;
	},
	async subirEdificios({dispatch}, payload){
		let serverUrl = await dispatch('getUrl');
		let respuesta = [];
		respuesta.errors = [];
		//await axios.get(serverUrl + 'sanctum/csrf-cookie');
		await axios({
					method: 'post',
					url: serverUrl + 'edificios',
					data: payload,
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer '+ localStorage.token
					}
		}).then(response => {
			respuesta =  response;
		}).catch(error => {
			respuesta.error = error;
			console.log(respuesta);
		});
		return respuesta;
	},
	Logout(state){
		localStorage.token = '';
		state.commit('SET_TOKEN', '');
	},
	getUrl({getters}){
		return getters.getUrl;
	},
	toggleLoader(state, payload){
		if(payload) {
			state.loader = !state.loader;
		} else {state.loader = payload;}
	},
}