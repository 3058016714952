<template>
	<form id="loginform" class="card w-50 p-4 m-auto text-left" v-on:submit.prevent="login">
		<div class="form-group">
			<label for="email" class="align-text-left">Email</label>
			<input class="form-control input-lg" @focus="errors = ''" v-model="email" type="email" name="email" id="email" placeholder="email" />
		</div>
		<div class="form-group">
			<label for="password">Contraseña</label>
			<input class="form-control input-lg" @focus="errors = ''" v-model="password" type="password" name="password" id="password" placeholder="password" />
		</div>
		<b-alert variant="success" show v-if="messages">{{messages}}</b-alert>
		<b-alert variant="warning" show v-if="errors">{{errors}}</b-alert>
		<div class="form-group text-center">
			<v-btn
			type="submit"
			color="primary"
			>
			Ingresar
			</v-btn>
		</div>
		{{ llave }}
	</form>
</template>

<script>
	import Vue from 'vue'
	import { uuid } from "vue-uuid";
	import { mapState, mapActions, mapMutations } from "vuex";

	export default {
		data() {
			return {
				email: "",
				password: "",
				messages: '',
				errors: '',
				createToken: '',
				deviceReady: false,
				DeviceId: '',
				settings: []
			}
		},
		components: {
		},
		mounted() {
			document.addEventListener('deviceReady', () => {
				if(Vue.cordova.device) {
					this.DeviceId = Vue.cordova.device;
				}
				if(!this.uuid) {
					if(!this.DeviceId.uuid) {
						this.DeviceId.uuid = uuid.v4();
					}
				}

				if (this.llave) {
					this.$router.push('reportes');
				}
			}, false)
		},
		methods: {
			...mapMutations([
				'toggleLoader'
			]),
			...mapActions([
				'postLogin'
			]),
			async login() {
				this.toggleLoader();

				var identificador = '';
				if(!this.DeviceId.serial && this.DeviceId.serial != 'unknown') {
					identificador = this.DeviceId.serial;
				} else {
					identificador = this.DeviceId.uuid;
				}
				var tokenID = this.DeviceId.model + ' ' +  this.DeviceId.platform + ' ' + identificador;
				//console.log(tokenID);
				var data = {
					email: this.email,
					password: this.password,
					createToken: this.llave,
					token: {
						token: this.llave,
						id: tokenID
					}
				}

				await this.postLogin(data).then( response => {
					this.toggleLoader();
					console.log(response);
					if(response.error && response.error.length > 0) {
						this.errors = response.error;
					} else {
						this.$router.push('reportes');
					}
				}).catch(error => {
					this.errors = error;
					console.log(error);
				})
			},
	},
	computed: {
		...mapState([
			'loader',
			'llave',
			'uuid'
		]),
	}
}
</script>