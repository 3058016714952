<template>
	<b-container>
		<b-row>
			<h3 v-if="formTitle">{{formTitle}}</h3>
			<!--{{ form }}-->
			<b-form @submit.prevent="submit" class="mt-5 row">
				<b-container fluid>
					<b-form-row>
						<b-col>
							<b-form-group
							label="Edificio:"
							label-for="edificio"
							>
								<b-form-input list="edificios" v-model="form.edificio"></b-form-input>
								<datalist id="edificios">
									<option>Edificio</option>
									<option v-for="edificio in edificios" v-bind:key="edificio">{{ edificio }}</option>
								</datalist>
							</b-form-group>
						</b-col>
						<b-col>
							<b-form-group
							label="Unidad:"
							label-for="unidad"
							>
								<b-form-input list="unidades" v-model="form.unidad"></b-form-input>
								<datalist id="unidades">
									<option>Unidad</option>
									<option v-for="unidad in unidades" v-bind:key="unidad">{{ unidad }}</option>
								</datalist>
							</b-form-group>
						</b-col>
						<b-col>
							<b-form-group
							label="Mes:"
							label-for="mes"
							>
								<b-form-input list="meses" v-model="form.mes"></b-form-input>
								<datalist id="meses">
									<option>Mes</option>
									<option v-for="mes in meses" v-bind:key="mes">{{ mes }}</option>
								</datalist>
							</b-form-group>
						</b-col>
						<b-col>
							<b-form-group
							label="Año:"
							label-for="ano"
							>
								<b-form-input list="anos" v-model="form.ano"></b-form-input>
								<datalist id="anos">
									<option>Año</option>
									<option v-for="ano in anos" v-bind:key="ano">{{ ano }}</option>
								</datalist>
							</b-form-group>
						</b-col>
					</b-form-row>
					<b-form-row>
						<b-col>
							<b-form-group
							label="Referencia:"
							label-for="referencia"
							>
							<b-form-input
							id="referencia"
							v-model="form.referencia"
							type="text"
							placeholder="Referencia"
							required
							></b-form-input>
						</b-form-group>
					</b-col>
					<b-col>
						<b-form-group
						label="Nombre:"
						label-for="nombre"
						>
						<b-form-input
						id="nombre"
						v-model="form.nombre"
						type="text"
						placeholder="Nombre"
						required
						></b-form-input>
					</b-form-group>
				</b-col>
				<b-col>
					<b-form-group
					id="input-group-1"
					label="Ci / Rif:"
					label-for="ci_rif"
					>
					<b-input-group>
						<template #prepend>
							<b-form-select cols-xs="3" v-model="form.rif_type" :options="rif_list"></b-form-select>
						</template>
						<b-form-input
						id="ci_rif"
						v-model="form.ci_rif"
						type="text"
						placeholder="CI / RIF"
						required
						></b-form-input>
					</b-input-group>
				</b-form-group>
			</b-col>
		</b-form-row>
		<b-form-row>
			<b-col>
				<b-form-group
				id="input-group-1"
				label="Banco Emisor:"
				label-for="banco_emisor"
				>
				<b-form-select
				id="banco_emisor"
				cols-xs="3"
				v-model="form.banco_emisor"
				:options="bancos_e"
				value-field="item"
				text-field="name"
				></b-form-select>
			</b-form-group>
		</b-col>
		<b-col>
			<b-form-group
			id="input-group-1"
			label="Banco Receptor:"
			label-for="banco_receptor"
			>
			<b-form-select
			cols-xs="3"
			v-model="form.banco_receptor"
			:options="bancos_r"
			value-field="item"
			text-field="name"
			></b-form-select>
		</b-form-group>
	</b-col>
</b-form-row>
<b-form-row>
	<b-col>
		<b-form-group
		id="input-group-1"
		label="Monto:"
		label-for="monto"
		>
		<v-currency-field
		outlined
		v-model="form.monto"/>
	</b-form-group>
</b-col>
<b-col>
	<b-form-group
	id="input-group-1"
	label="Fecha:"
	label-for="fecha"
	>
	<b-form-datepicker
	id="fecha"
	:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
	locale="es"
	></b-form-datepicker>
</b-form-group>
</b-col>
</b-form-row>
</b-container>
	<b-col cols="12" class="p-0">
		<b-alert show variant="success" v-if="messages">{{messages}}</b-alert>
		<b-alert show variant="danger" v-if="errors"><i class="fas fa-exclamation-triangle"></i>{{errors}}</b-alert>
	</b-col>
	<b-col cols="12" class="text-right">
		<v-btn class="mr-4" type="submit" color="primary">
			<v-icon right dark>fas fa-plus</v-icon> Agregar
		</v-btn>
	</b-col>
</b-form>
</b-row>
</b-container>
</template>

<script>
	import { required, digits, email, max, regex } from 'vee-validate/dist/rules'
	import { extend, setInteractionMode } from 'vee-validate'
	import VCurrencyField from '@/components/VueCurrencyField.vue';
	import { mapActions } from "vuex";
	//import axios from 'axios';
	//import qs from 'qs';
	//import { VuetifyMoney } from '@/components/VuetifyMoney.vue';

	export default {
		name: 'AddReports',
		components: {
			//ValidationProvider,
			//ValidationObserver,
			VCurrencyField,
		},
		props: {
			errors: {
				type: String,
				default: ''
			},
			messages: {
				type: String,
				default: ''
			},
			nombre: {
				type: String,
				default: ''
			},
			fecha: {
				type: String,
				default(){
					return new Date().toISOString().substr(0, 10);
				}
			},
			referencia: {
				type: String,
				default: ''
			},
			rif_type: {
				type: String,
				default: 'V'
			},
			ci_rif: {
				type: Number,
				default: null
			},
			banco_emisor: {
				type: String,
				default: ''
			},
			banco_receptor: {
				type: String,
				default: ''
			},
			monto: {
				type: Number,
				default: 0
			},
			date: {
				type: String,
				default(){
					return new Date().toISOString().substr(0, 10);
				}
			},
			rif_list: Array,
			bancos_e: Array,
			bancos_r: Array,
			formTitle: String,
			itemID: Number
		},
		data() {
			return {
				menu: false,
				modal: false,
				menu2: false,
				formErrors: [
				'no puede estar vacío'
				],
				rules: {
					required: value => !!value || 'Required.',
					min: v => v.length >= 8 || 'Min 8 characters',
				},
				email: '',
				emailRules: [
				v=> !!v || 'E-mail is required',
				v => /.+@.+/.test(v) || 'E-mail must be valid',
				],
				form: {
					referencia: this.referencia,
					fecha: this.fecha,
					monto: this.monto,
					nombre: this.nombre,
					rif_type: this.rif_type,
					ci_rif: this.ci_rif,
					banco_emisor: this.banco_emisor,
					banco_receptor: this.banco_receptor,
				},
				dismissSecs: 10,
				dismissCountDown: 0,
				showDismissibleMessage: false,
				edificios: [
					'Marimar 1029',
					'Plaza Estrella 1481',
					'Astor 1568',
					'Remilld 1980',
					'Torre N 1031',
					'Asturias 1030',
					'Doralcentro 1536',
					'Doral Caracas 1748',
				],
				unidades: [
					'001',
					'002',
					'003',
					'004',
					'005',
					'006',
					'007',
					'008',
				],
				meses: [
					'01 Enero',
					'02 Febrero',
					'03 Marzo',
					'04 Abril',
					'05 Mayo',
					'06 Junio',
					'07 Julio',
					'08 Agosto',
					'09 Septiembre',
					'10 Octubre',
					'11 Noviembre',
					'12 Diciembre',
				],
				anos: [
					'2019',
					'2020',
					'2021',
				],
			}
		},

		computed: {
			computedDateFormatted () {
				return this.formatDate(this.date)
			},
		},
		watch: {
			date() {
				this.dateFormatted = this.formatDate(this.date)
			},
			monto(){
				this.form.monto = this.form.monto.toLocaleString('es-ES', {minimumFractionDigits: 2, maximumFractionDigits: 2});
			}
		},
		methods: {
			...mapActions([
				'editReporte',
				'toggleLoader'
				]),
			//dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
			async submit () {
				let reporte_id = null;
				//this.$refs.observer.validate();
				this.toggleLoader(true);
				console.log(this.form.referencia);
				if(this.itemID) {
					this.reporte_id = reporte_id;
				}

				const datos = {
					referencia: this.form.referencia,
					nombre: this.form.nombre,
					ci_rif: this.form.rif_type + this.form.ci_rif.padStart(9, "0"),
					banco_emisor: this.form.banco_emisor,
					banco_receptor: this.form.banco_receptor,
					monto: this.form.monto,
					fecha: this.form.fecha,
					id: this.reporte_id,
					observaciones: '',
					status: 'pendiente'
				}
				this.toggleLoader(false);

				await this.editReporte(datos).then( response => {
					console.log(response);
					if(response.status == 201) {
						this.clear();
						this.messages = 'Reporte creado satisfactoriamente';
					}
					if(response.error && response.error.length > 0) {
						this.errors = response.error;
					} else {
						//this.$router.push('reportes');
					}
				}).catch(error => {
					this.errors = error;
					console.log(error);
				})

				/*axios({
					method: 'post',
					url: this.settings.server_url + 'reportes',
					data: datos,
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer '+TOKEN
					}
				})
				.then(response => {
					console.log(response);
					if (response.status === 201) {
						this.messages = 'Reporte creado satisfactoriamente';
						this.clear();
					}
				}).catch(error => {
					if(error.response) {
						if (error.response.status === 401) {
							this.messages = error.response.data.message;
						}
							//console.log("ERRRR:: ",error.response);
						} else {
							this.errors = 'Error en la conexión con el servidor';
						}
					});*/
				},
				countDownChanged(dismissCountDown) {
					this.dismissCountDown = dismissCountDown
				},
				showAlert() {
					this.dismissCountDown = this.dismissSecs
				},
				clear () {
					this.form.referencia = '',
					this.form.nombre = '',
					this.form.ci_rif = '',
					this.form.banco_emisor = '',
					this.form.banco_receptor = '',
					this.form.monto = 0,
					this.form.fecha = new Date().toISOString().substr(0, 10),
			//this.focusInput(this.$refs.referencia),
			//this.$refs.observer.reset()
			document.getElementById("referencia").focus()
		},
		formatDate (date) {
			if (!date) return null

				const [year, month, day] = date.split('-')
			return `${day}/${month}/${year}`
		},
		parseDate (date) {
			if (!date) return null

				const [day, month, year] = date.split('/')
			return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
		},
	},
}
setInteractionMode('eager')

extend('digits', {
	...digits,
	message: '{_field_} needs to be {length} digits. ({_value_})',
})

extend('required', {
	...required,
	message: '{_field_} can not be empty',
})

extend('max', {
	...max,
	message: '{_field_} may not be greater than {length} characters',
})

extend('regex', {
	...regex,
	message: '{_field_} {_value_} does not match {regex}',
})

extend('email', {
	...email,
	message: 'Email must be valid',
})








/*export default {
name: 'AddReports',
		components: {
			ValidationProvider,
			ValidationObserver,
			VCurrencyField,
			reportForm
		},
		data: vm => ({
			date: new Date().toISOString().substr(0, 10),
			dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
			menu: false,
			modal: false,
			menu2: false,
			titulo: 'Agregar Reporte',
			password: 'Password',
			monto: 0,
				//value: 2545454,
				referencia: null,
				rif_type: 'V',
				ci_rif: null,
				nombre: null,
				banco_emisor: null,
				banco_receptor: null,
				errors: '',
				messages: '',
				rules: {
					required: value => !!value || 'Required.',
					min: v => v.length >= 8 || 'Min 8 characters',
					emailMatch: () => (`The email and password you entered don't match`),
				},
				rif_list: [
					'V',
					'J',
					'G'
				],
				bancos_e: [
				'Banesco',
				'Provincial',
				'Venezolano de Crédito',
				],
				bancos_r: [
				'Banesco',
				'Provincial',
				'Venezolano de Crédito',
				],
				email: '',
				emailRules: [
				v=> !!v || 'E-mail is required',
				v => /.+@.+/.test(v) || 'E-mail must be valid',
				],
				fecha: false
			}),

		computed: {
			computedDateFormatted () {
				return this.formatDate(this.date)
			},
			...mapState([
				'loader',
				'db',
				'settings'
			])
		},

		watch: {
			date() {
				this.dateFormatted = this.formatDate(this.date)
			},
		},
		methods: {
			submit () {
				this.$refs.observer.validate()
		//const BASEURL = 'http://localhost:8000/api';
		//const ENDPOINT = '/reportes';

		const datos = {
			referencia: this.referencia,
			nombre: this.nombre,
			ci_rif: this.rif_type + this.ci_rif.padStart(9, "0"),
			banco_emisor: this.banco_emisor,
			banco_receptor: this.banco_receptor,
			monto: this.monto,
			fecha: this.date
		}
		console.log(qs.stringify(datos));

		axios({
			method: 'post',
			url: this.settings.server_url + 'reportes',
			data: datos,
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+this.dbSettings.token
			}
		})
		.then(response => {
			console.log(response);
			if (response.status === 201) {
				this.messages = 'Reporte creado satisfactoriamente';
				this.clear();
			}
		}).catch(error => {
			if(error.response) {
				if (error.response.status === 401) {
					this.messages = error.response.data.message;
				}
					//console.log("ERRRR:: ",error.response);
				} else {
					this.errors = 'Error en la conexión con el servidor';
				}
			});
	},
	clear () {
		this.referencia = '',
		this.nombre = '',
		this.ci_rif = '',
		this.banco_emisor = '',
		this.banco_receptor = '',
		this.monto = 0,
		this.date = new Date().toISOString().substr(0, 10),
		this.focusInput(this.$refs.referencia),
		this.$refs.observer.reset()
	},
	formatDate (date) {
		if (!date) return null

			const [year, month, day] = date.split('-')
		return `${day}/${month}/${year}`
	},
	parseDate (date) {
		if (!date) return null

			const [day, month, year] = date.split('/')
		return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
	},
	focusInput(field) {
      field.focus();
    },
},
}
setInteractionMode('eager')

extend('digits', {
	...digits,
	message: '{_field_} needs to be {length} digits. ({_value_})',
})

extend('required', {
	...required,
	message: '{_field_} can not be empty',
})

extend('max', {
	...max,
	message: '{_field_} may not be greater than {length} characters',
})

extend('regex', {
	...regex,
	message: '{_field_} {_value_} does not match {regex}',
})

extend('email', {
	...email,
	message: 'Email must be valid',
})*/
</script>