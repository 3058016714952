<template>
	<div>
		<FormEmpleados />
	</div>
</template>
<script>
	import FormEmpleados from '@/components/FormEmpleados.vue';
	export default {
		components: {
			FormEmpleados
		},
	}
</script>