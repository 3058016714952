<template>
		<div id="login" class="login container">
		<h1>Ingresar al sistema</h1>
		<div class="row align-items-center justify-content-center d-flex">
		<LoginForm/>
		</div>
		</div>
</template>
<script>
	// @ is an alias to /src
	import LoginForm from '@/components/Login.vue'

	export default {
		name: 'Login',
		components: {
			LoginForm
		}
	}
</script>