<template>
	<div class="menu_principal">
		<b-button v-b-toggle.sidebar-1>Toggle Sidebar</b-button>
		<router-link to="/">
			<v-btn color="white" text value="home" >Home</v-btn>
		</router-link>
		<router-link to="/login" v-if="!token">
			<v-btn color="white" text value="login" >Ingresar</v-btn>
		</router-link>
		<router-link to="/logout" v-if="token" class="float-sm-right">
			<v-btn color="white" text value="logout" ><i class="fas fa-sign-out-alt"></i></v-btn>
		</router-link>
		<div class="messages">{{messages}}</div>
</div>
</template>
<script>
	import { mapState } from "vuex";
	export default {
		name: 'Menu',
		data() {
			return {
				messages: '',
				edificios: [],
			}
		},
		props:[
			'token'
		],
		computed:{
			...mapState([
				'llave'
			])
		}
	}
</script>