<template>
	<div id="content" data-app>
		<Reporte />
	</div>
</template>
<script>
	import Reporte from '@/components/Reporte.vue';

	export default {
		components: {
			Reporte
		}
	}
</script>