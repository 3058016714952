<template>
	<div id="content">
		<validation-observer ref="observer">
			<h1>{{ titulo }}</h1>
			<form @submit.prevent="submit" data-app class="pb-5">
				<div class="row pa-6 mt-5">
					<div class="form-group col-sm-6 pb-0">
						<validation-provider
						name="Archivo"
						rules="required|max:10"
						>
						<v-file-input
						v-model="archivo"
						label="Archivo"
						prepend-icon="far fa-file-alt"
						@focus="clearErrors"
						@change="onFilePicked"
						filled
						required
						></v-file-input>
					</validation-provider>
				</div>
				<div class="form-group col-sm-6 pb-0">
					<v-select
					@focus="clearErrors"
					v-model="banco"
					:items="bancos_e"
					label="Banco"
					data-vv-name="banco"
					prepend-icon="fas fa-university"
					filled
					required
					></v-select>
				</div>
			</div>
			<b-alert show variant="success" v-if="messages" v-html="messages"></b-alert>
			<b-alert show variant="warning" v-if="formMessage"><i class="fas fa-exclamation-triangle"></i> <span v-html="formMessage"></span></b-alert>
			<b-alert show dismissible variant="danger" v-if="errors.length > 0">
				<ul class="pl-5 m-0">
					<li v-for="error in errors" :key="error.index">
						<i class="fas fa-exclamation-triangle"></i> {{ error }}
					</li>
				</ul>
			</b-alert>
			<div class="row justify-content-end">
				<div class="form-group col-sm-6 pt-0">
			<v-btn class="primary float-right" right @click="submit" > Agregar
				<v-icon right dark>
					fas fa-plus
				</v-icon>
			</v-btn>
		</div>
	</div>
</form>
</validation-observer>
<v-card>
	<v-card-title>
		<v-text-field
		v-model="search"
		append-icon="mdi-magnify"
		label="Search"
		single-line
		hide-details
		class="elevation-1 col-sm-12"
		></v-text-field>
	</v-card-title>
	<v-card-text>
		<v-container>
			<v-row>
				<v-data-table
				:headers="headers"
				:items="datosReporte"
				:search="search"
				loading
				:loading-text="tableMessage"
				:items-per-page="100"
				class="elevation-1 col-sm-12"
				></v-data-table>
			</v-row></v-container></v-card-text>
		</v-card>
	</div>
</template>
<script>
	import { required } from 'vee-validate/dist/rules'
	import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
	import { mapActions, mapMutations } from "vuex";
//import VCurrencyField from '@/components/VueCurrencyField.vue';
//import axios from 'axios';
import qs from 'qs';
//import { VuetifyMoney } from '@/components/VuetifyMoney.vue';

export default {
	name: 'ReporteBancario',
	data(){
		return {
			//TOKEN: localStorage.token,
			titulo: 'Agregar Reporte',
			search: '',
			banco: '',
			errors: [],
			messages: '',
			tableMessage: 'Recibiendo Información, por favor espera...',
			formMessage: '',
			/*'fecha',
			'referencia',
			'descripcion',
			'monto',
			'archivo',
			'banco',
			'id_reporte',
			'status',*/
			headers: [
			{ text: 'ID', value: 'id'},
			{ text: 'Fecha', value: 'fecha'},
			{ text: 'Referencia', value: 'referencia' },
			{ text: 'CI/Rif', value: 'ci_rif' },
			/*{ text: 'Descripción', value: 'Descripción' },*/
			{ text: 'Banco', value: 'banco_emisor' },
			{ text: 'Monto', value: 'monto' },
			],
			datosReporte: [],
			archivo: [],
			bancos_e: [
				'Banesco',
				/*'Provincial',
				'Venezolano de Crédito'*/
			],
			rules: {
				required: value => !!value || 'Required.',
			}
		}
	},
	components: {
		ValidationProvider,
		ValidationObserver,
		//VCurrencyField
	},
	mounted(){
		this.toggleLoader(false);
		this.get_reportes();
	},
methods: {
	...mapMutations([
		'toggleLoader'
	]),
	...mapActions([
		'getReportesBancarios',
		'subirReporteBancario'
	]),
	async get_reportes() {
		this.toggleLoader(true);

		await this.getReportesBancarios().then( res => {
			this.toggleLoader(false);
			console.log(res);
			if(res.errors && res.errors.length > 0) {
				this.errors.push(res.error);
			} else {
				if(typeof res.data == 'string') {
					this.tableMessage = res.data;
				} else if(res.data.length > 0) {
					this.datosReporte = res.data;
				} else {
					this.tableMessage = 'No se encontraron reportes';
				}
			}
		})
	},
	async subir_reporte(formData) {
		this.toggleLoader(true);
		await this.subirReporteBancario(formData).then( res => {
			console.log(res);
			if(res.errors) {
				if(res.error.response.data.message){
					this.errors.push(res.error.response.data.message);
				}
				this.errors.push(res.error);
			} else {
				if(typeof res.data == 'string') {
					this.formMessage = res.data;
				} else if(res.data.length > 0) {
					this.datosReporte = res.data;
				} else if(res.data.message) {
					this.formMessage = res.data.message;
					this.tableMessage = res.data.message;
				} else {
					this.formMessage = 'No se encontraron reportes';
				}
			}
			this.toggleLoader(false);
			console.log(this.errors);
		}).catch(error => {
			console.log(error);
			this.error = error;
		});
	},
	filterOnlyCapsText (value, search) {
		return value != null &&
		search != null &&
		typeof value === 'string' &&
		value.toString().toLocaleUpperCase().indexOf(search) !== -1
	},
	submit () {
		this.errors = []
		this.toggleLoader();
		this.$refs.observer.validate();
		this.datosReporte = [];

		let formData = new FormData();

		var inputs = document.getElementsByTagName('input');

		for(var i = 0; i < inputs.length; i++) {
			if(inputs[i].type.toLowerCase() == 'file') {
				console.log(inputs[i].files[0]);
				formData.append('archivos[' + i + ']', inputs[i].files[0]);
			}
		}
		formData.append('banco', this.banco);

		const datos = {
			archivo: this.archivo.file,
			banco: this.banco
		}
		console.log(qs.stringify(datos));
		this.subir_reporte(formData);
/*axios({
	method: 'post',
	url: this.settings.server_url+'reporte_bancario',
	data: formData,
	headers: {
		'Content-Type': 'application/json',
		'Authorization': 'Bearer '+TOKEN
	}
})
.then(response => {
	console.log(response);
	if (response.status === 201) {
		this.messages = response.data.message;
	}
	this.datosReporte = response.data;
}).catch(error => {
	if(error.response) {
		if (error.response.status === 500) {
			this.messages = 'Error en el servidor';
		}
		if (error.response.status === 401) {
			this.messages = error.response.data.message;
		}
		if (error.response.status === 301) {
			this.errors = error.response.data.message;
		}
//console.log("ERRRR:: ",error.response);
}
});*/
},
clear () {
	this.name = ''
	this.phoneNumber = ''
	this.email = ''
	this.select = null
	this.checkbox = null
	this.$refs.observer.reset()
	this.clearErrors
},
clearErrors () {
	this.errors = []
	this.tableMessage = ''
	this.formMessage = ''
},

onFilePicked(e) {
	this.archivo = e;
}
},
}

setInteractionMode('eager')


extend('required', {
	...required,
	message: '{_field_} can not be empty',
})

</script>