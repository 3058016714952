export default {
	getUrl(state){
		return state.server_url
	},
	isAuthenticated(state){
		if(state.llave || localStorage.token){
			return true
		} else {
			console.log('State Token: ' + state.token);
			console.log('Localstorage Token: ' + localStorage.token);
			return false
		}
	}
}