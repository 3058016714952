<template>
	<div id="content" data-app>
		<Table />
	</div>
</template>
<script>
	import Table from '@/components/Reportes.vue';

	export default {
		components: {
			Table
		}
	}
</script>