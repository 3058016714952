<template>
	<div id="AddReports" class="content" data-app>
		<h1>{{ titulo }}</h1>
		<reportForm
			v-bind:rif_list=rif_list
			v-bind:bancos_e=bancos_e
			v-bind:bancos_r=bancos_r
		/>
	</div>
</template>
<script>
	import reportForm from '@/components/reporteForm.vue';
	//import { mapState } from 'vuex';

	export default {
		data(){
			return {
				titulo: 'Agregar Reporte',
				errors: '',
				messages: '',
				rif_list: [
				'V',
				'J',
				'G'
				],
				bancos_e: [
				{item: '0156', name: '100%BANCO'},
				{item: '0196', name: 'ABN AMRO BANK'},
				{item: '0172', name: 'BANCAMIGA BANCO MICROFINANCIERO, C.A.'},
				{item: '0171', name: 'BANCO ACTIVO BANCO COMERCIAL, C.A.'},
				{item: '0166', name: 'BANCO AGRICOLA'},
				{item: '0175', name: 'BANCO BICENTENARIO'},
				{item: '0128', name: 'BANCO CARONI, C.A. BANCO UNIVERSAL'},
				{item: '0001', name: 'BANCO CENTRAL DE VENEZUELA.'},
				{item: '0102', name: 'BANCO DE VENEZUELA S.A.I.C.A.'},
				{item: '0114', name: 'BANCO DEL CARIBE C.A.'},
				{item: '0163', name: 'BANCO DEL TESORO'},
				{item: '0176', name: 'BANCO ESPIRITO SANTO, S.A.'},
				{item: '0115', name: 'BANCO EXTERIOR C.A.'},
				{item: '0173', name: 'BANCO INTERNACIONAL DE DESARROLLO, C.A.'},
				{item: '0105', name: 'BANCO MERCANTIL C.A.'},
				{item: '0191', name: 'BANCO NACIONAL DE CREDITO'},
				{item: '0116', name: 'BANCO OCCIDENTAL DE DESCUENTO.'},
				{item: '0138', name: 'BANCO PLAZA'},
				{item: '0108', name: 'BANCO PROVINCIAL BBVA'},
				{item: '0104', name: 'BANCO VENEZOLANO DE CREDITO S.A.'},
				{item: '0168', name: 'BANCRECER S.A. BANCO DE DESARROLLO'},
				{item: '0134', name: 'BANESCO'},
				{item: '0177', name: 'BANFANB'},
				{item: '0146', name: 'BANGENTE'},
				{item: '0174', name: 'BANPLUS BANCO COMERCIAL C.A'},
				{item: '0190', name: 'CITIBANK.'},
				{item: '0157', name: 'DELSUR BANCO UNIVERSAL'},
				{item: '0151', name: 'FONDO COMUN'},
				{item: '0601', name: 'INSTITUTO MUNICIPAL DE CRÉDITO POPULAR'},
				{item: '0169', name: 'MIBANCO BANCO DE DESARROLLO, C.A.'},
				{item: '0137', name: 'SOFITASA'},
				],
				bancos_r: [
				{item: '0134', name: 'BANESCO'},
				{item: '0108', name: 'BANCO PROVINCIAL BBVA'},
				{item: '0104', name: 'BANCO VENEZOLANO DE CREDITO S.A.'}
				],
			}
		},
		components: {
			reportForm
		}
	}

/*
'0156 100%BANCO'
'0196 ABN AMRO BANK'
'0172 BANCAMIGA BANCO MICROFINANCIERO, C.A.'
'0171 BANCO ACTIVO BANCO COMERCIAL, C.A.'
'0166 BANCO AGRICOLA'
'0175 BANCO BICENTENARIO'
'0128 BANCO CARONI, C.A. BANCO UNIVERSAL'
'0001 BANCO CENTRAL DE VENEZUELA.'
'0102 BANCO DE VENEZUELA S.A.I.C.A.'
'0114 BANCO DEL CARIBE C.A.'
'0163 BANCO DEL TESORO'
'0176 BANCO ESPIRITO SANTO, S.A.'
'0115 BANCO EXTERIOR C.A.'
'0173 BANCO INTERNACIONAL DE DESARROLLO, C.A.'
'0105 BANCO MERCANTIL C.A.'
'0191 BANCO NACIONAL DE CREDITO'
'0116 BANCO OCCIDENTAL DE DESCUENTO.'
'0138 BANCO PLAZA'
'0108 BANCO PROVINCIAL BBVA'
'0104 BANCO VENEZOLANO DE CREDITO S.A.'
'0168 BANCRECER S.A. BANCO DE DESARROLLO'
'0177 BANFANB'
'0146 BANGENTE'
'0174 BANPLUS BANCO COMERCIAL C.A'
'0190 CITIBANK.'
'0157 DELSUR BANCO UNIVERSAL'
'0151 FONDO COMUN'
'0601 INSTITUTO MUNICIPAL DE CRÉDITO POPULAR'
'0169 MIBANCO BANCO DE DESARROLLO, C.A.'
'0137 SOFITASA'
*/
</script>