<template>
	<div id="footer">
		<div class="text-center">{{token}}</div>
	</div>
</template>
<script>
	export default {
		name: 'Footer',
		props: [
			'token'
		]
	}
</script>