	<template>
		<v-container fluid>
			<v-row>
				<h3 v-if="formTitle">{{formTitle}}</h3>
	<hr/>
	<validation-observer ref="observer" tag="div"
	class="col-sm-12 formempleados" >
	<form @submit.prevent="submit" class="mt-5 row">
		<v-row class="empleado">
			<v-col cols="12">
				<validation-provider
				name="Naturaleza"
				rules="required|max:100"
				v-slot="{ fromErrors }"
				tag="div"
				class="col-sm-12"
				>
				<v-radio-group v-model="radioGroup">
					<div class="d-flex cols-sm-12">
						<label class="mr-5">Naturaleza</label>
						<v-radio
						label="Natural"
						value="natural"
						class="mr-5"
						></v-radio>
						<v-radio
						label="Jurìdica"
						value="juridica"
						class="mr-5"
						></v-radio>
					</div>
				</v-radio-group>
				<span class="error">{{ fromErrors }}</span>
			</validation-provider>
		</v-col>
		<v-col cols="3">
			<validation-provider
				name="rif_type"
				rules="required|max:100"
				v-slot="{ fromErrors }"
				tag="div"
				class="col-sm-12"
				>
				<v-select v-model="rif_type" :items="rif_list" filled label="CI/Rif" dense></v-select>
				<span class="error">{{ fromErrors }}</span>
			</validation-provider>
		</v-col>
		<v-col cols="9">
			<validation-provider
			name="ci_rif"
			rules="required|max:100"
			v-slot="{ fromErrors }"
			tag="div"
			class="col-sm-12"
			>
			<v-text-field
			:counter="10"
			label="CI / Rif"
			filled
			required
			></v-text-field>
			<span class="error">{{ fromErrors }}</span>
		</validation-provider>
	</v-col>
	<v-col cols="6">
			<validation-provider
			name="nombres"
			rules="required|max:100"
			v-slot="{ fromErrors }"
			tag="div"
			class="col-sm-12"
			>
			<v-text-field
			:counter="10"
			label="Nombres"
			filled
			required
			></v-text-field>
			<span class="error">{{ fromErrors }}</span>
		</validation-provider>
	</v-col>
	<v-col cols="6">
			<validation-provider
			name="apellidos"
			rules="required|max:100"
			v-slot="{ fromErrors }"
			tag="div"
			class="col-sm-12"
			>
			<v-text-field
			:counter="10"
			label="Apellidos"
			filled
			required
			></v-text-field>
			<span class="error">{{ fromErrors }}</span>
		</validation-provider>
	</v-col>
<v-col
cols="12"
>
<v-textarea
filled
name="input-7-4"
label="Dirección Fiscal"
></v-textarea>
</v-col>
<v-col cols="6">
			<validation-provider
			name="telefono"
			rules="required|max:100"
			v-slot="{ fromErrors }"
			tag="div"
			class="col-sm-12"
			>
			<v-text-field
			:counter="10"
			label="Teléfono"
			filled
			required
			></v-text-field>
			<span class="error">{{ fromErrors }}</span>
		</validation-provider>
	</v-col>
	<v-col cols="6">
			<validation-provider
			name="email"
			rules="required|max:100"
			v-slot="{ fromErrors }"
			tag="div"
			class="col-sm-12"
			>
			<v-text-field
			:counter="10"
			label="Email"
			filled
			required
			></v-text-field>
			<span class="error">{{ fromErrors }}</span>
		</validation-provider>
	</v-col>
	<v-col cols="12">
			<validation-provider
				name="rol"
				rules="required|max:100"
				v-slot="{ fromErrors }"
				tag="div"
				class="col-sm-12"
				>
				<v-select v-model="rol" :items="roles" filled label="Rol de Usuario" dense></v-select>
				<span class="error">{{ fromErrors }}</span>
			</validation-provider>
		</v-col>
</v-row>

<b-alert show variant="success" v-if="messages">{{messages}}</b-alert>
<b-alert show dismissible variant="danger" v-if="errors"><i class="fas fa-exclamation-triangle"></i> {{errors}}</b-alert>
<v-col cols="12" class="form-buttons">
	<v-btn class="mr-4" type="submit" color="primary">
		<v-icon right dark>fas fa-save</v-icon> Guardar
	</v-btn>
</v-col>
</form>
</validation-observer>
</v-row>
</v-container>
</template>
<script>
	import { ValidationObserver, ValidationProvider } from 'vee-validate'
	export default {
		data(){
			return {
				formTitle: 'Agregar Usuario',
				codigo: '',
				nombre:'',
				direccion: '',
				cuota: '',
				propietario: '',
				contacto: '',
				personal: '',
				selected: null,
				cuotas: [
				{ value: null, text: 'Cuota' },
				'1',
				'2',
				'3',
				],
				propietarios: [
				{ value: 1, text: 'Deivi Silva V0012345678' },
				{ value: 2, text: 'Gerald Rausseo V00131234556' }
				],
				empleados: 0,
				unidades: 0,
				nombres: '',
				apellidos: '',
				ci_rif: '',
				rif_type:'',
				messages: '',
				errors: '',
				rif_list: [
				'V',
				'E',
				'J',
				'G'
				],
				roles: [
				'Cliente',
				'Cobranzas',
				'Administrador',
				],
			}
		},
		name: 'AddEmpleados',
		components: {
			ValidationProvider,
			ValidationObserver
		},
		methods: {
			sumaEmpleado(){
				this.empleados += 1;
			},
			sumaUnidades(){
				this.unidades += 1;
			}
		}
	}
</script>