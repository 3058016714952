<template>
	<div class="content">
		<h1>{{ title }}</h1>
		<login />
	</div>
</template>

<script>
	import Login from '../components/Login'

	export default {
		name: 'Home',
		data(){
			return {
				title: 'Home'
			}
		},
		components: {
			Login
		},
	}
</script>