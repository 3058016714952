<template>
	<div id="content" data-app>
		<AgregarEdificio />
	</div>
</template>
<script>
	import AgregarEdificio from '@/components/AgregarEdificio.vue';

	export default {
		components: {
			AgregarEdificio
		}
	}
</script>