<template>
	<div id="sidebar" class="p2">
		<b-sidebar id="sidebar-1" title="Sidebar">
			<b-nav>
			<hr>
			<b-button block v-b-toggle.menu-reportes class="text-left mt-2">Reportes</b-button>
			<b-collapse id="menu-reportes" is-nav="true" visible accordion="sidebar-menu" role="tabpanel">
				<router-link to="/reportes"> Ver Reportes </router-link>
				<router-link to="/add-reportes"> Agregar Reportes </router-link>
			</b-collapse>
			<b-button block v-b-toggle.menu-reportes-bancarios class="text-left mt-2">Reportes Bancarios</b-button>
			<b-collapse id="menu-reportes-bancarios" visible accordion="sidebar-menu" role="tabpanel">
				<router-link to="/reporte-bancario"> Ver Reportes </router-link>
				<router-link to="/conciliacion"> Conciliación </router-link>
			</b-collapse>
			<b-button block v-b-toggle.menu-edificios class="text-left mt-2">Edificios</b-button>
			<b-collapse id="menu-edificios" visible accordion="sidebar-menu" role="tabpanel">
				<router-link to="/edificios"> Edificios </router-link>
				<router-link to="/agregar-edificio"> Agregar Edificios </router-link>
			</b-collapse>
			<b-button block v-b-toggle.menu-usuarios class="text-left mt-2">Usuarios</b-button>
			<b-collapse id="menu-usuarios" visible accordion="sidebar-menu" role="tabpanel">
				<router-link to="/cargar-usuario"> Agregar Usuario </router-link>
			</b-collapse>
			<!--<b-nav-item-dropdown text="Reportes">
				<router-link to="/reportes"> Ver Reportes </router-link>
				<router-link to="/add-reportes"> Agregar Reportes </router-link>
			</b-nav-item-dropdown>-->
				<!--<router-link to="/reporte-bancario"> Reportes Bancarios </router-link>
				<router-link to="/conciliacion"> Conciliación </router-link>-->	
				<!--<b-nav-item-dropdown text="Edificios">
					<router-link to="/edificios"> Edificios </router-link>
					<router-link to="/agregar-edificio"> Agregar Edificios </router-link>
					<router-link to="/cargar-edificios"> Cargar Edificios </router-link>
				</b-nav-item-dropdown>-->
				<!--<b-nav-item-dropdown text="Usuarios">
					<router-link to="/Usuarios"> Usuarios </router-link>
					<router-link to="/cargar-usuario"> Agregar Usuario </router-link>
					<router-link to="/cargar-Usuarios"> Cargar Usuarios </router-link>
				</b-nav-item-dropdown>-->
		</b-nav>
		</b-sidebar>
	</div>
</template>
<script>
	//import { mapState } from "vuex";
	export default{
		/*data(){
			return {
				token: ''
			}
		},
		computed: {
			...mapState([
				'loader',
				'dbSettings'
			])
		}*/
	}
</script>