<template>
	<div id="content">
		<Table />
		<!--<ReporteModal />-->
	</div>
</template>
<script>
	import Table from '@/components/Conciliacion.vue';
	//import ReporteModal from '@/components/reporteModal.vue';
	import { mapState } from "vuex";

	export default {
		data() {
			return {
				url: localStorage.token
			}
		},
		components: {
			Table
		},
		computed: {
			...mapState([
				'db',
				'settings'
			])
		}
	}
</script>