<template>
	<div>
		<h1>{{ titulo }}</h1>
		{{datos}}
		<v-dialog
		v-model="dialog"
		max-width="500px"
		>
			<v-container>
				<v-row>
					<v-card>
						<v-col class="pl-4 pr-4">
							<formReporte
							v-bind:referencia=editedItem.referencia
							v-bind:nombre=editedItem.nombre
							v-bind:rif_type=editedItem.rif_type
							v-bind:ci_rif=editedItem.ci_rif
							v-bind:banco_emisor=editedItem.banco_emisor
							v-bind:banco_receptor=editedItem.banco_receptor
							v-bind:monto=editedItem.monto
							v-bind:date=editedItem.fecha
							v-bind:formTitle=editedItem.formTitle
							v-bind:itemID=editedItem.id
							v-bind:rif_list=rif_list
							v-bind:bancos_e=bancos_e
							v-bind:bancos_r=bancos_r
							>
							</formReporte>
						</v-col>
					</v-card>
				</v-row>
			</v-container>
		</v-dialog>

		<v-card>
			<v-card-title>
				<v-text-field
				v-model="search"
				append-icon="mdi-magnify"
				label="Search"
				single-line
				hide-details
				></v-text-field>
			</v-card-title>
			<v-data-table
			:headers="headers"
			:items="datos"
			:search="search"
			:items-per-page="10"
			class="elevation-1"
			loading
			:loading-text="tableMessage"
			>
			<template
			v-slot:item.acciones="{ item }"
			>
			<v-card-actions>
				<v-icon
				small
				class="mr-2"
				@click="editItem(item)"
				>
				mdi-pencil
				</v-icon>
				<v-icon
				small
				@click="deleteItem(item)"
				>
				mdi-delete
				</v-icon>
			</v-card-actions>
			<v-dialog v-model="dialogDelete" max-width="500px">
				<v-card>
					<v-card-title class="headline">¿Seguro de que quieres eliminar este reporte?</v-card-title>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
						<v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
						<v-spacer></v-spacer>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</template>
		</v-data-table>
		</v-card>
		{{ errors }}
	</div>
</template>
<script>
	//import axios from 'axios';
	import { mapState, mapMutations, mapActions } from "vuex";
	import formReporte from '@/components/reporteForm.vue';

	export default {
		components: {
			formReporte
		},
		props: {
		},
		data() {
			return {
				reporte: [],
				search: '',
				token: '',
				formTitle: '',
				editedItemID: '',
				messages: '',
				messagesEdit: '',
				tableMessage: 'Recibiendo información, por favor espera...',
				errors: '',
				dialog: false,
				dialogDelete: false,
				titulo: 'Reportes',
				datos: [],
				headers: [
				{
					text: 'id',
					align: 'start',
					sortable: false,
					value: 'id',
				},
				{
					text: 'nombre',
					value: 'nombre',
					filter: value => {
						if (!this.nombre) return true

							return value
					},
				},
				{ text: 'referencia', value: 'referencia' },
				{ text: 'CI/Rif', value: 'ci_rif' },
				{ text: 'monto', value: 'monto' },
				{ text: 'Fecha', value: 'fecha' },
				{ text: 'Banco Emisor', value: 'banco_emisor' },
				{ text: 'Banco Receptor', value: 'banco_receptor' },
				{ text: 'Estado', value: 'status' },
				{ text: 'Acciones', value: 'acciones' },
				],
				rif_list: [
					'V',
					'J',
					'G'
				],
				bancos_e: [
					'Mercantil',
					'Banesco',
					'Provincial',
					'Venezolano de Crédito',
				],
				bancos_r: [
					'Banesco',
					'Provincial',
					'Venezolano de Crédito',
				],
				editedIndex: -1,
				editedItem: {
					referencia: '',
					nombre: '',
					rif_type: '',
					ci_rif: 0,
					banco_emisor: null,
					banco_receptor: null,
					monto: 0,
					id: null,
					date: null
				},
				defaultItem: {
					referencia: '',
					ci_rif: 0,
					banco_emisor: 0,
					banco_receptor: 0,
				},
			}
		},
		computed: {
			...mapState([
				'loader',
				'server_url'
			]),
			formatMonto(monto){
				monto = new Intl.NumberFormat("es-ES").format(monto);
				return monto;
			}
		},
		mounted(){
			//console.log('Route: ' + this.$route.params.id);
			this.get_reporte(this.$route.params.id);
		},
		methods: {
			...mapMutations([
				'toggleLoader'
			]),
			...mapActions([
				'getReporte',
				'deleteReporte'
			]),
			/*formatter: (value, key, item) => {
				let formatter = new Intl.NumberFormat("es-ES", {
					style: "currency",
					currency: "EUR",
					minimumFractionDigits: 2
				});
				return formatter.format(value);
			},*/
			async get_reporte() {
				this.toggleLoader(true);
				//console.log('Route: ' + this.$route.params.id);
				await this.getReporte(this.$route.params.id).then( respuesta => {
					this.toggleLoader(false);
					//console.log(this.$route.params.id);
					console.log(respuesta);
					this.datos.push(respuesta);
					if(respuesta.length > 0){
						this.datos.push(respuesta);
					}
					if(respuesta.errors && respuesta.errors.length > 0) {
						this.errors.push(respuesta.error);
					} else {
						if(typeof respuesta.data == 'string') {
							this.tableMessage = respuesta.data;
						} else if(respuesta.length > 0) {
							this.datos = respuesta;
							console.log(this.datos);
						} else {
							this.tableMessage = 'No se encontraron reportes';
						}
					}
				})
			},
			editItem (item) {
				this.editedItem.referencia = item.referencia
				this.editedItem.nombre = item.nombre
				this.editedItem.rif_type = item.ci_rif.substring(0, 1)
				this.editedItem.banco_emisor = item.banco_emisor
				this.editedItem.banco_receptor = item.banco_receptor
				this.editedItem.monto = item.monto
				this.editedItem.fecha = item.fecha
				console.log('Fecha: ' + item.fecha);
				this.editedItem.ci_rif = item.ci_rif.substring(1)
				this.editedItem.formTitle = 'Editar Reporte #'+item.id
				this.editedItem.id = item.id
				this.editedIndex = this.datos.indexOf(item)
				this.editedItem.editedItem = Object.assign({}, item)
				this.dialog = true
			},
			deleteItem (item) {
				this.editedIndex = this.datos.indexOf(item)
				this.editedItem = Object.assign({}, item)
				this.dialogDelete = true
			},

			deleteItemConfirm () {
				this.datos.splice(this.editedIndex, 1)
				this.reporteID = this.editedItem.id;
				this.toggleLoader(true);
				this.closeDelete();
				this.delete(this.reporteID);
			},

			async delete (reporteID) {
				await this.deleteReporte(reporteID).then( response => {
					console.log(response);
					this.toggleLoader(false);
					if(response.status == 200) {
						this.messages = 'Reporte eliminado satisfactoriamente';
					}
					if(response.error && response.error.length > 0) {
						this.errors = response.error;
					}/* else {
						this.$router.push('reportes');
					}*/
				}).catch(error => {
					this.errors = error;
					console.log(error);
				})
			},

			close () {
				this.dialog = false
				this.$nextTick(() => {
					this.editedItem = Object.assign({}, this.defaultItem)
					this.editedIndex = -1
				})
			},

			closeDelete () {
				this.dialogDelete = false
				this.$nextTick(() => {
					this.editedItem = Object.assign({}, this.defaultItem)
					this.editedIndex = -1
				})
			},
			formatDate (date) {
				if (!date) return null

				const [year, month, day] = date.split('-')
				return `${day}/${month}/${year}`
			},
			/*save () {
				if (this.editedItemID > -1) {
					const BASEURL = this.settings.server_url;
					const ENDPOINT = '/reportes/2';

					const datos = {
						referencia: this.editedItem.referencia,
						nombre: this.editedItem.nombre,
						ci_rif: this.editedItem.rif_type + this.editedItem.ci_rif.padStart(9, "0"),
						banco_emisor: this.editedItem.banco_emisor,
						banco_receptor: this.editedItem.banco_receptor,
						monto: this.editedItem.monto,
						fecha: this.editedItem.date
					}
					console.log(datos);

					axios.create({
						baseURL: BASEURL,
						data: datos,
						headers: {
							'Content-Type': 'application/json',
							'Authorization': 'Bearer '+localStorage.token
						}
					})
					.put(ENDPOINT)
					.then(response => {
						this.messagesEdit = response.data;
						//this.close()
					});

					Object.assign(this.datos[this.editedIndex], this.editedItem)
				} else {
					this.datos.push(this.editedItem)
				}
			},*/
		},
		watch: {
			dialog (val) {
				val || this.close()
			},
			dialogDelete (val) {
				val || this.closeDelete()
			},
		}
	}
</script>