<template>
	<v-col  cols="12">
	<v-row>
		<h3 v-if="formTitle">{{formTitle}}</h3>
		<!--
			<li v-for="index in 10" :key="index">
    {{ shoppingItems[index].name }} - {{ shoppingItems[index].price }}
  </li>
		-->
		<h4>Empleados</h4>
		<hr/>
		<validation-observer ref="observer" tag="div"
					class="col-sm-12 formempleados" >
			<form @submit.prevent="submit" class="mt-5 row">
				<v-row v-for="index in empleados" :key="index" class="empleado">
				<validation-provider
					name="Nombres"
					rules="required|max:100"
					v-slot="{ fromErrors }"
					tag="div"
					class="col-sm-12"
				>
					<v-text-field
						v-model="nombres"
						:counter="10"
						label="Nombres"
						filled
						required
					></v-text-field>
					<span class="error">{{ fromErrors }}</span>
				</validation-provider>
				<validation-provider
					name="Apellidos"
					rules="required|max:50"
					v-slot="{ fromErrors }"
					tag="div"
					class="col-sm-12"
				>
					<v-text-field
						v-model="apellidos"
						:counter="10"
						label="Apellidos"
						filled
						required
					></v-text-field>
					<span class="error">{{ fromErrors }}</span>
				</validation-provider>
				<validation-provider
					name="CI/Rif"
					rules="required|max:50"
					v-slot="{ fromErrors }"
					tag="div"
					class="col-sm-12 d-flex"
				>
					<v-col class="d-flex pl-0" cols="3" sm="3">
						<v-select v-model="rif_type" :items="rif_list" filled label="CI/Rif" dense></v-select>
					</v-col>
					<v-col class="d-flex pr-0" cols="9" sm="9">
						<v-text-field
							v-model="ci_rif"
							:counter="10"
							label="CI/Rif"
							filled
							required
						></v-text-field>
					</v-col>
					<span class="error">{{ fromErrors }}</span>
				</validation-provider>
				</v-row>

				<b-alert show variant="success" v-if="messages">{{messages}}</b-alert>
				<b-alert show dismissible variant="danger" v-if="errors"><i class="fas fa-exclamation-triangle"></i> {{errors}}</b-alert>
				<v-col cols="12" class="form-buttons">
					<v-btn elevation="2" @click="sumaEmpleado()">
						<v-icon right dark>fas fa-plus</v-icon> Agregar Empleado
					</v-btn>
					<v-btn class="mr-4" type="submit" color="primary">
						<v-icon right dark>fas fa-save</v-icon> Guardar
					</v-btn>
				</v-col>
			</form>
		</validation-observer>
	</v-row>
</v-col>
</template>
<script>
	import { ValidationObserver, ValidationProvider } from 'vee-validate'
export default {
	data(){
		return {
			empleados: 1,
			nombres: '',
			apellidos: '',
			ci_rif: '',
			rif_type:'',
			messages: '',
			errors: '',
			rif_list: [
				'V',
				'J',
				'G'
			],
		}
	},
	name: 'AddEmpleados',
	components: {
		ValidationProvider,
		ValidationObserver
	},
	methods: {
		sumaEmpleado(){
			this.empleados += 1;
		}
	}
}
</script>