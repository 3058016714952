<template>
	<div class="col-sm-12">
		<h2>Loggin Out</h2>
		<b-alert variant="success" show v-if="messages">{{ messages }}</b-alert>
		<b-alert variant="danger" show v-if="error">{{ error }}</b-alert>
	</div>
</template>
<script>
	import { mapActions } from 'vuex';
	export default {
		name: 'Logout',
		data(){
			return {
				messages: [],
				error: []
			}
		},
		mounted() {
			this.Logout()
		},
		methods: {
			...mapActions([
				'Logout'
			]),
		}
	}
</script>