<template>
	<div id="content">
		<validation-observer ref="observer">
			<h1>{{ titulo }}</h1>
			<form @submit.prevent="submit" data-app class="pb-5">
				<div class="row pa-6 mt-5">
					<div class="form-group col-sm-6 pb-0">
						<validation-provider
						name="Archivo"
						rules="required|max:10"
						>
						<v-file-input
						v-model="archivo"
						label="Archivo"
						prepend-icon="far fa-file-alt"
						@focus="clearErrors"
						@change="onFilePicked"
						filled
						required
						></v-file-input>
					</validation-provider>
				</div>
			</div>
			<b-alert show variant="success" v-if="messages" v-html="messages"></b-alert>
			<b-alert show variant="warning" v-if="formMessage"><i class="fas fa-exclamation-triangle"></i> <span v-html="formMessage"></span></b-alert>
			<b-alert show dismissible variant="danger" v-if="errors.length > 0">
				<ul class="pl-5 m-0">
					<li v-for="error in errors" :key="error.index">
						<i class="fas fa-exclamation-triangle"></i> {{ error }}
					</li>
				</ul>
			</b-alert>
			<div class="row justify-content-end">
				<div class="form-group col-sm-6 pt-0">
			<v-btn class="primary float-right" right @click="submit" > Agregar
				<v-icon right dark>
					fas fa-plus
				</v-icon>
			</v-btn>
		</div>
	</div>
</form>
</validation-observer>
<v-card>
	<v-card-title>
		<v-text-field
		v-model="search"
		append-icon="mdi-magnify"
		label="Search"
		single-line
		hide-details
		class="elevation-1 col-sm-12"
		></v-text-field>
	</v-card-title>
	<v-card-text>
		<v-container>
			<v-row>
				<v-data-table
				:headers="headers"
				:items="datosEdificios"
				:search="search"
				loading
				:loading-text="tableMessage"
				:items-per-page="100"
				class="elevation-1 col-sm-12"
				></v-data-table>
			</v-row></v-container></v-card-text>
		</v-card>
	</div>
</template>
<script>
	//import { required } from 'vee-validate/dist/rules'
	import { ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
	import { mapActions, mapMutations } from "vuex";
//import VCurrencyField from '@/components/VueCurrencyField.vue';
//import axios from 'axios';
import qs from 'qs';
//import { VuetifyMoney } from '@/components/VuetifyMoney.vue';

export default {
	name: 'Edificios',
	data(){
		return {
			//TOKEN: localStorage.token,
			titulo: 'Agregar Reporte',
			search: '',
			errors: [],
			messages: '',
			tableMessage: 'Recibiendo Información, por favor espera...',
			formMessage: '',
			headers: [
				{ text: 'ID', value: 'id'},
				{ text: 'Código', value: 'codigo'},
				{ text: 'Nombre', value: 'nombre'},
				{ text: 'Dirección', value: 'direccion'},
			],
			datosEdificios: [],
			archivo: []
		}
	},
	components: {
		ValidationProvider,
		ValidationObserver,
		//VCurrencyField
	},
	mounted(){
		this.toggleLoader(false);
		this.get_edificios();
	},
methods: {
	...mapMutations([
		'toggleLoader'
	]),
	...mapActions([
		'getEdificios',
		'subirEdificios'
	]),
	async get_edificios() {
		this.toggleLoader(true);

		await this.getEdificios().then( res => {
			this.toggleLoader(false);
			console.log(res);
			if(res.errors && res.errors.length > 0) {
				this.errors.push(res.error);
			} else {
				if(typeof res.data == 'string') {
					this.tableMessage = res.data;
				} else if(res.data.length > 0) {
					this.datosEdificios = res.data;
				} else {
					this.tableMessage = 'No se encontraron reportes';
				}
			}
		})
	},
	async subir_edificios(formData) {
		this.toggleLoader(true);
		await this.subirEdificios(formData).then( res => {
			console.log(res);
			if(res.errors) {
				if(res.error.response.data.message){
					this.errors.push(res.error.response.data.message);
				}
				this.errors.push(res.error);
			} else {
				if(typeof res.data == 'string') {
					this.formMessage = res.data;
				} else if(res.data.length > 0) {
					this.datosEdificios = res.data;
				} else if(res.data.message) {
					this.formMessage = res.data.message;
					this.tableMessage = res.data.message;
				} else {
					this.formMessage = 'No se encontraron reportes';
				}
			}
			this.toggleLoader(false);
			console.log(this.errors);
		}).catch(error => {
			console.log(error);
			this.error = error;
		});
	},
	filterOnlyCapsText (value, search) {
		return value != null &&
		search != null &&
		typeof value === 'string' &&
		value.toString().toLocaleUpperCase().indexOf(search) !== -1
	},
	submit () {
		this.errors = []
		this.toggleLoader();
		this.$refs.observer.validate();
		this.datosReporte = [];

		let formData = new FormData();

		var inputs = document.getElementsByTagName('input');

		for(var i = 0; i < inputs.length; i++) {
			if(inputs[i].type.toLowerCase() == 'file') {
				console.log(inputs[i].files[0]);
				formData.append('archivos[' + i + ']', inputs[i].files[0]);
			}
		}
		formData.append('banco', this.banco);

		const datos = {
			archivo: this.archivo.file,
			banco: this.banco
		}
		console.log(qs.stringify(datos));
		this.subir_reporte(formData);
},
clear () {
	this.name = ''
	this.phoneNumber = ''
	this.email = ''
	this.select = null
	this.checkbox = null
	this.$refs.observer.reset()
	this.clearErrors
},
clearErrors () {
	this.errors = []
	this.tableMessage = ''
	this.formMessage = ''
},

onFilePicked(e) {
	this.archivo = e;
}
},
}

setInteractionMode('eager')

</script>