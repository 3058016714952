<template>
  <v-text-field
    ref="input"
    v-currency="{
    locale: 'en',
    currency: 'USD',
    valueAsInteger: false,
    distractionFree: false,
    precision: 2,
    autoDecimalMode: true,
    valueRange: { min: 0 },
    allowNegative: false
  }"
    :value="formattedValue"
    @change="onChange"
    @input="onInput"
    label="Monto"
	filled
	required
  />
</template>

<script>
export default {
  name: "VCurrencyField",
  props: {
    value: {
      type: Number,
      default: null
    },
    options: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      formattedValue: null
    };
  },
  watch: {
    value(value) {
      this.setValue(value);
    }
  },
  mounted() {
    this.setValue(this.value);
  },
  methods: {
    setValue(value) {
      this.$ci.setValue(this.$refs.input, value);
    },
    onInput(value) {
      this.$emit("input", this.$ci.getValue(this.$refs.input));
      this.formattedValue = value;
    },
    onChange(value) {
      this.$emit("change", this.$ci.getValue(this.$refs.input));
      this.formattedValue = value;
    }
  }
};
</script>
