<template>
	<div id="content">
		<Table />
	</div>
</template>
<script>
	import Table from '@/components/Reportes.vue';

	export default {
		components: {
			Table
		},
		mounted() {
			document.addEventListener('deviceReady', () => {
			}, false)
		},
	}
</script>