<template>
	<ReporteBancario />
</template>

<script>
	import ReporteBancario from '../components/ReporteBancario'

	export default {
		name: 'reporte-bancario',

		components: {
			ReporteBancario,
		},
	}
</script>