<template>
	<div id="content" data-app>
		<CargarUsuario />
	</div>
</template>
<script>
	import CargarUsuario from '@/components/CargarUsuario.vue';
	export default {
		components: {
			CargarUsuario
		}
	}
</script>