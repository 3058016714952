import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
//import { VTooltip } from 'v-tooltip';
import VueCurrencyInput from "vue-currency-input";
import { BootstrapVue } from 'bootstrap-vue'

// import
import VueCordovaDevice from 'vue-cordova-device'
Vue.use(VueCordovaDevice)

Vue.config.productionTip = false

Vue.use(VueCurrencyInput);

Vue.use(BootstrapVue)


//Vue.directive('tooltip', VTooltip);
import './assets/scss/app.scss'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@fortawesome/fontawesome-free/css/all.css'

//Vue.use(vuetify)

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')