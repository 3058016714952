var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"content","data-app":""}},[_c('h1',[_vm._v(_vm._s(_vm.titulo))]),_c('b-card',[_c('b-card-title',[_c('h3',[_vm._v("Conciliados")])]),_c('b-card-subtitle',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.datos,"search":_vm.search,"items-per-page":10,"loading":"","loading-text":"Recibiendo Información, por favor espera..."},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var value = ref.value;
return [_c('router-link',{attrs:{"to":("/reporte/" + value)}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.acciones",fn:function(ref){
var item = ref.item;
return [_c('v-card-actions',[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])],1),_c('v-dialog',[(_vm.messages)?_c('b-alert',{attrs:{"show":"","variant":"success"}},[_vm._v(_vm._s(_vm.messages))]):_vm._e(),(_vm.errors)?_c('b-alert',{attrs:{"show":"","dismissible":"","variant":"danger"}},[_c('i',{staticClass:"fas fa-exclamation-triangle"}),_vm._v(" "+_vm._s(_vm.errors))]):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Are you sure you want to delete this item?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)]}}])})],1),_c('b-card',[_c('b-card-title',[_c('h3',[_vm._v("Posibles Coincidencias")])]),_c('b-card-subtitle',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.datosPosibles,"search":_vm.search,"items-per-page":10,"loading":"","loading-text":"Recibiendo Información, por favor espera..."},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var value = ref.value;
return [_c('router-link',{attrs:{"to":("/reporte/" + value)}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.acciones",fn:function(ref){
var item = ref.item;
return [_c('v-card-actions',[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])],1),_c('v-dialog',[(_vm.messages)?_c('b-alert',{attrs:{"show":"","variant":"success"}},[_vm._v(_vm._s(_vm.messages))]):_vm._e(),(_vm.errors)?_c('b-alert',{attrs:{"show":"","dismissible":"","variant":"danger"}},[_c('i',{staticClass:"fas fa-exclamation-triangle"}),_vm._v(" "+_vm._s(_vm.errors))]):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Are you sure you want to delete this item?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }