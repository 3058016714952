import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
	icons: {
		iconfont: 'fa',
		values: {
			cancel: 'fas fa-ban',
			menu: 'fas fa-ellipsis-v',
		},
	},
	theme: {
		dark: false,
		primary: '#4CAF50',
		secondary: '#FFCDD2',
		themes: {
			light: {
				primary: '#4CAF50',

				secondary: '#FFCDD2',

				accent: '#3F51B5',

				search: '#E0E0E0',

				searchButton: '#A5D6A7',

				newsBlock: '#F5F5F5',

				// info: colors.lighten1,

				// warning: colors.darken2,

				// error: colors.accent4,

				// success: colors.lighten2,
			},
			dark: {
				primary: colors.blue.lighten3,
			},

		}
	}
});