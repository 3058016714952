import Vue from 'vue'
import Vuex from 'vuex'
import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

Vue.use(Vuex)
export default new Vuex.Store({
		state,
		mutations,
		actions,
		getters,
		modules: {
		}
	});
/*document.addEventListener('deviceReady', () => {
	const store =  
	store.init;
}, false)*/