<template>
	<b-container fluid>
		<h3 v-if="formTitle">{{formTitle}}</h3>
		<b-form-row>
			<b-col>
				<b-form-group
				label="Código:"
				label-for="codigo"
				>
					<b-form-input
					id="codigo"
					v-model="form.codigo"
					type="text"
					placeholder="Código"
					required
					></b-form-input>
				</b-form-group>
			</b-col>
			<b-col>
				<b-form-group
				label="Nombre:"
				label-for="nombre"
				>
					<b-form-input
					id="nombre"
					v-model="form.nombre"
					type="text"
					placeholder="Nombre"
					required
					></b-form-input>
				</b-form-group>
			</b-col>
			<b-col>
				<b-form-group
				label="Cuota:"
				label-for="cuota"
				>
					<b-form-input list="cuotas" v-model="form.cuota"></b-form-input>
					<datalist id="cuotas">
						<option>Cuota</option>
						<option v-for="cuota in cuotas" v-bind:key="cuota">{{ cuota }}</option>
					</datalist>
				</b-form-group>
			</b-col>
		</b-form-row>
		<b-form-row>
			<b-col>
				<b-form-group
				label="Propietario:"
				label-for="propietario"
				>
					<b-form-select v-model="form.propietario" list="propietarios" :options="propietarios"></b-form-select>
					<b-form-input list="propietarios" v-model="form.propietario"></b-form-input>
					<datalist id="propietarios">
						<option>Propietario</option>
						<option
							:value="propietario.value"
							v-for="propietario in propietarios"
							v-bind:key="propietario">{{ propietario.text }}</option>
					</datalist>
				</b-form-group>
			</b-col>
		</b-form-row>
		<b-form-row>
			<b-col>
				<b-form-group
				label="Dirección:"
				label-for="direccion"
				>
					<b-form-textarea
					id="direccion"
					name="direccion"
					v-model="direccion"
					placeholder="Dirección del edificio"
					rows="2"
					max-rows="4"
					></b-form-textarea>
				</b-form-group>
			</b-col>
		</b-form-row>
		<v-row>
		<hr/>
<validation-observer ref="observer" tag="div"
class="col-sm-12 formempleados" >
<form @submit.prevent="submit" class="mt-5 row">
	<v-row class="empleado">
<v-col
cols="12"
>
<v-select
:items="propietarios"
filled
label="Propietario"
></v-select>
</v-col>
<v-col
cols="12"
>
<v-textarea
filled
name="input-7-4"
label="Dirección"
></v-textarea>
</v-col>
<v-container v-if="unidades">
	<v-col cols="12">
		<h3>Unidades</h3>
	</v-col>
	<v-row cols="12" v-for="index in unidades" :key="index" class="unidad" style="background: rgba(0,0,0,0.03); margin-bottom: 15px;">
		<v-col cols="4">
			<validation-provider
			name="Codigo"
			rules="required|max:100"
			v-slot="{ fromErrors }"
			tag="div"
			>
			<v-text-field
			v-model="codigo"
			:counter="10"
			label="Código"
			filled
			required
			></v-text-field>
			<span class="error">{{ fromErrors }}</span>
		</validation-provider>
	</v-col>
	<v-col cols="4">
		<validation-provider
			name="mts"
			rules="required|max:100"
			v-slot="{ fromErrors }"
			tag="div"
			>
			<v-text-field
			v-model="mts"
			:counter="10"
			label="Mts"
			filled
			required
			></v-text-field>
			<span class="error">{{ fromErrors }}</span>
		</validation-provider>
	</v-col>
	<v-col cols="4">
		<v-select
		:items="tipo"
		filled
		label="Tipo"
		></v-select>
	</v-col>
	<v-col cols="12">
		<label>Contrato</label>
		<v-row>
			<v-col cols="6">
				<v-checkbox
					cols="6"
					v-model="contrato"
					label="Alquiler"
					color="indigo darken-3"
					value="alquiler"
					hide-details
				></v-checkbox>
			</v-col>
			<v-col cols="6">
				<v-checkbox
					cols="6"
					v-model="contrato"
					label="Condominio"
					color="indigo darken-3"
					value="condominio"
					hide-details
				></v-checkbox>
			</v-col>
		</v-row>
	</v-col>
</v-row>
</v-container>
<v-col cols="12" class="form-buttons">
	<v-btn elevation="2" @click="sumaUnidades()">
		<v-icon right dark>fas fa-plus</v-icon> Agregar Unidades
	</v-btn>
</v-col>
</v-row>
<v-row v-for="index in empleados" :key="index" class="empleado">
	<h4>Empleados</h4>
	<validation-provider
	name="Nombres"
	rules="required|max:100"
	v-slot="{ fromErrors }"
	tag="div"
	class="col-sm-12"
	>
	<v-text-field
	v-model="nombres"
	:counter="10"
	label="Nombres"
	filled
	required
	></v-text-field>
	<span class="error">{{ fromErrors }}</span>
</validation-provider>
<validation-provider
name="Apellidos"
rules="required|max:50"
v-slot="{ fromErrors }"
tag="div"
class="col-sm-12"
>
<v-text-field
v-model="apellidos"
:counter="10"
label="Apellidos"
filled
required
></v-text-field>
<span class="error">{{ fromErrors }}</span>
</validation-provider>
<validation-provider
name="CI/Rif"
rules="required|max:50"
v-slot="{ fromErrors }"
tag="div"
class="col-sm-12 d-flex"
>
<v-col class="d-flex pl-0" cols="3" sm="3">
	<v-select v-model="rif_type" :items="rif_list" filled label="CI/Rif" dense></v-select>
</v-col>
<v-col class="d-flex pr-0" cols="9" sm="9">
	<v-text-field
	v-model="ci_rif"
	:counter="10"
	label="CI/Rif"
	filled
	required
	></v-text-field>
</v-col>
<span class="error">{{ fromErrors }}</span>
</validation-provider>
</v-row>

<b-alert show variant="success" v-if="messages">{{messages}}</b-alert>
<b-alert show dismissible variant="danger" v-if="errors"><i class="fas fa-exclamation-triangle"></i> {{errors}}</b-alert>
<v-col cols="12" class="form-buttons">
	<v-btn elevation="2" @click="sumaEmpleado()">
		<v-icon right dark>fas fa-plus</v-icon> Agregar Empleado
	</v-btn>
	<v-btn class="mr-4" type="submit" color="primary">
		<v-icon right dark>fas fa-save</v-icon> Guardar
	</v-btn>
</v-col>
</form>
</validation-observer>
</v-row>
</b-container>
</template>
<script>
	import { ValidationObserver, ValidationProvider } from 'vee-validate'
	export default {
		data(){
			return {
				formTitle: 'Agregar Edificio',
				codigo: '',
				nombre:'',
				direccion: '',
				cuota: '',
				propietario: '',
				contacto: '',
				personal: '',
				selected: null,
				cuotas: [
				'1',
				'2',
				'3',
				],
				tipo: [
				{ value: null, text: 'Tipo de Unidad' },
					'Vivienda',
					'Oficina',
					'Local',
				],
				propietarios: [
				{ value: 1, text: 'Deivi Silva V0012345678' },
				{ value: 2, text: 'Gerald Rausseo V00131234556' }
				],
				empleados: 0,
				unidades: 0,
				nombres: '',
				apellidos: '',
				ci_rif: '',
				rif_type:'',
				messages: '',
				errors: '',
				rif_list: [
				'V',
				'J',
				'G'
				],
				contrato: [
				'condominio',
				],
				form: [{
					'codigo': '',
					'nombre': '',
					'direccion': '',
					'cuota': Number,
					'propietario': Number
				}]
			}
		},
		name: 'AddEmpleados',
		components: {
			ValidationProvider,
			ValidationObserver
		},
		methods: {
			sumaEmpleado(){
				this.empleados += 1;
			},
			sumaUnidades(){
				this.unidades += 1;
			}
		}
	}
</script>