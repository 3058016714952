var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-text-field',{directives:[{name:"currency",rawName:"v-currency",value:({
    locale: 'en',
    currency: 'USD',
    valueAsInteger: false,
    distractionFree: false,
    precision: 2,
    autoDecimalMode: true,
    valueRange: { min: 0 },
    allowNegative: false
  }),expression:"{\n    locale: 'en',\n    currency: 'USD',\n    valueAsInteger: false,\n    distractionFree: false,\n    precision: 2,\n    autoDecimalMode: true,\n    valueRange: { min: 0 },\n    allowNegative: false\n  }"}],ref:"input",attrs:{"value":_vm.formattedValue,"label":"Monto","filled":"","required":""},on:{"change":_vm.onChange,"input":_vm.onInput}})}
var staticRenderFns = []

export { render, staticRenderFns }