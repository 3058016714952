var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[(_vm.formTitle)?_c('h3',[_vm._v(_vm._s(_vm.formTitle))]):_vm._e(),_c('hr'),_c('validation-observer',{ref:"observer",staticClass:"col-sm-12 formempleados",attrs:{"tag":"div"}},[_c('form',{staticClass:"mt-5 row",on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('v-row',{staticClass:"empleado"},[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{staticClass:"col-sm-12",attrs:{"name":"Naturaleza","rules":"required|max:100","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var fromErrors = ref.fromErrors;
return [_c('v-radio-group',{model:{value:(_vm.radioGroup),callback:function ($$v) {_vm.radioGroup=$$v},expression:"radioGroup"}},[_c('div',{staticClass:"d-flex cols-sm-12"},[_c('label',{staticClass:"mr-5"},[_vm._v("Naturaleza")]),_c('v-radio',{staticClass:"mr-5",attrs:{"label":"Natural","value":"natural"}}),_c('v-radio',{staticClass:"mr-5",attrs:{"label":"Jurìdica","value":"juridica"}})],1)]),_c('span',{staticClass:"error"},[_vm._v(_vm._s(fromErrors))])]}}])})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{staticClass:"col-sm-12",attrs:{"name":"rif_type","rules":"required|max:100","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var fromErrors = ref.fromErrors;
return [_c('v-select',{attrs:{"items":_vm.rif_list,"filled":"","label":"CI/Rif","dense":""},model:{value:(_vm.rif_type),callback:function ($$v) {_vm.rif_type=$$v},expression:"rif_type"}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(fromErrors))])]}}])})],1),_c('v-col',{attrs:{"cols":"9"}},[_c('validation-provider',{staticClass:"col-sm-12",attrs:{"name":"ci_rif","rules":"required|max:100","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var fromErrors = ref.fromErrors;
return [_c('v-text-field',{attrs:{"counter":10,"label":"CI / Rif","filled":"","required":""}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(fromErrors))])]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{staticClass:"col-sm-12",attrs:{"name":"nombres","rules":"required|max:100","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var fromErrors = ref.fromErrors;
return [_c('v-text-field',{attrs:{"counter":10,"label":"Nombres","filled":"","required":""}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(fromErrors))])]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{staticClass:"col-sm-12",attrs:{"name":"apellidos","rules":"required|max:100","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var fromErrors = ref.fromErrors;
return [_c('v-text-field',{attrs:{"counter":10,"label":"Apellidos","filled":"","required":""}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(fromErrors))])]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"filled":"","name":"input-7-4","label":"Dirección Fiscal"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{staticClass:"col-sm-12",attrs:{"name":"telefono","rules":"required|max:100","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var fromErrors = ref.fromErrors;
return [_c('v-text-field',{attrs:{"counter":10,"label":"Teléfono","filled":"","required":""}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(fromErrors))])]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{staticClass:"col-sm-12",attrs:{"name":"email","rules":"required|max:100","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var fromErrors = ref.fromErrors;
return [_c('v-text-field',{attrs:{"counter":10,"label":"Email","filled":"","required":""}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(fromErrors))])]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{staticClass:"col-sm-12",attrs:{"name":"rol","rules":"required|max:100","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var fromErrors = ref.fromErrors;
return [_c('v-select',{attrs:{"items":_vm.roles,"filled":"","label":"Rol de Usuario","dense":""},model:{value:(_vm.rol),callback:function ($$v) {_vm.rol=$$v},expression:"rol"}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(fromErrors))])]}}])})],1)],1),(_vm.messages)?_c('b-alert',{attrs:{"show":"","variant":"success"}},[_vm._v(_vm._s(_vm.messages))]):_vm._e(),(_vm.errors)?_c('b-alert',{attrs:{"show":"","dismissible":"","variant":"danger"}},[_c('i',{staticClass:"fas fa-exclamation-triangle"}),_vm._v(" "+_vm._s(_vm.errors))]):_vm._e(),_c('v-col',{staticClass:"form-buttons",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mr-4",attrs:{"type":"submit","color":"primary"}},[_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("fas fa-save")]),_vm._v(" Guardar ")],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }