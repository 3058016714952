import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Logout from '../views/Logout.vue'
import Dashboard from '../views/Dashboard.vue'
import Reportes from '../views/Reportes.vue'
import AddReportes from '../views/AddReportes.vue'
import Reporte from '../views/Reporte.vue'
import Conciliacion from '../views/Conciliacion.vue'
import ReporteBancario from '../views/ReporteBancario.vue'
import Edificios from '../views/Edificios.vue'
import CargarEdificios from '../views/CargarEdificios.vue'
import AgregarEdificio from '../views/AgregarEdificio.vue'
import CargarUsuario from '../views/CargarUsuario.vue'

Vue.use(VueRouter)

const ifAuthenticated = (to, from, next) => {
  console.log('Authenticated: ' + store.getters.isAuthenticated);
  if (!store.getters.isAuthenticated) {
    next('login');
  } else {next()}
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/reportes',
    name: 'Reportes',
    component: Reportes,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/reporte-bancario',
    name: 'ReporteBancario',
    component: ReporteBancario,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/add-reportes',
    name: 'AddReportes',
    component: AddReportes,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/reporte/:id',
    name: 'Reporte',
    component: Reporte,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/conciliacion',
    name: 'Conciliacion',
    component: Conciliacion,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/edificios',
    name: 'Edificios',
    component: Edificios,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/agregar-edificio',
    name: 'AgregarEdificio',
    component: AgregarEdificio,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/cargar-edificios',
    name: 'CargarEdificios',
    component: CargarEdificios,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/cargar-usuario',
    name: 'CargarUsuario',
    component: CargarUsuario,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router