<template>
	<div id="content" data-app>
		<h1>{{ titulo }}</h1>
<b-card>
	<b-card-title>
		<h3>Conciliados</h3>
	</b-card-title>
	<b-card-subtitle>
		<v-text-field
		v-model="search"
		append-icon="mdi-magnify"
		label="Search"
		single-line
		hide-details
		></v-text-field>
	</b-card-subtitle>
	<v-data-table
		:headers="headers"
		:items="datos"
		:search="search"
		:items-per-page="10"
		class="elevation-1"
		loading
		loading-text="Recibiendo Información, por favor espera..."
	>
	<template #item.id="{ value }">
		<router-link :to="`/reporte/${value}`"> {{ value }} </router-link>
	</template>
	<template
	v-slot:item.acciones="{ item }"
	>
	<v-card-actions>
		<v-icon
		small
		class="mr-2"
		@click="editItem(item)"
		>
		mdi-pencil
	</v-icon>
	<v-icon
	small
	@click="deleteItem(item)"
	>
	mdi-delete
</v-icon>
</v-card-actions>
<v-dialog>
	<b-alert show variant="success" v-if="messages">{{messages}}</b-alert>
	<b-alert show dismissible variant="danger" v-if="errors"><i class="fas fa-exclamation-triangle"></i> {{errors}}</b-alert>
</v-dialog>
<v-dialog v-model="dialogDelete" max-width="500px">
	<v-card>
		<v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
			<v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
			<v-spacer></v-spacer>
		</v-card-actions>
	</v-card>
</v-dialog>
</template>
</v-data-table>
</b-card>

<b-card>
	<b-card-title>
		<h3>Posibles Coincidencias</h3>
	</b-card-title>
	<b-card-subtitle>
		<v-text-field
		v-model="search"
		append-icon="mdi-magnify"
		label="Search"
		single-line
		hide-details
		></v-text-field>
	</b-card-subtitle>
	<v-data-table
		:headers="headers"
		:items="datosPosibles"
		:search="search"
		:items-per-page="10"
		class="elevation-1"
		loading
		loading-text="Recibiendo Información, por favor espera..."
	>
	<template #item.id="{ value }">
		<router-link :to="`/reporte/${value}`"> {{ value }} </router-link>
	</template>
	<template
	v-slot:item.acciones="{ item }"
	>
	<v-card-actions>
		<v-icon
		small
		class="mr-2"
		@click="editItem(item)"
		>
		mdi-pencil
	</v-icon>
	<v-icon
	small
	@click="deleteItem(item)"
	>
	mdi-delete
</v-icon>
</v-card-actions>
<v-dialog>
	<b-alert show variant="success" v-if="messages">{{messages}}</b-alert>
	<b-alert show dismissible variant="danger" v-if="errors"><i class="fas fa-exclamation-triangle"></i> {{errors}}</b-alert>
</v-dialog>
<v-dialog v-model="dialogDelete" max-width="500px">
	<v-card>
		<v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
			<v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
			<v-spacer></v-spacer>
		</v-card-actions>
	</v-card>
</v-dialog>
</template>
</v-data-table>
</b-card>
</div>
</template>
<script>
	//import axios from 'axios';
	import { mapActions } from "vuex";
	//import VCurrencyField from '@/components/VueCurrencyField.vue';

	export default {
		data(){
			return {
				search: '',
				formTitle: '',
				messages: '',
				errors: '',
				titulo: 'Conciliacion',
				headers: [
					{text: 'ID', value:'id'},
					{text: 'Referencia', value:'referencia'},
					{text: 'CI/Rif', value:'ci_rif'},
					{text: 'Fecha', value:'fecha'},
					{text: 'Monto', value:'monto'},
					{text: 'Nombre', value:'nombre'},
					{text: 'Emisor', value:'banco_emisor'},
					{text: 'Receptor', value:'banco_receptor'},
					{text: 'Observaciones', value:'observaciones'},
					{text: 'Reporte', value:'reporte_bancario.id'},
					{text: 'Estado', value:'status'},
				],
				datos: [],
				datosPosibles: []
			}
		},
		computed: {
			computedDateFormatted () {
				return this.formatDate(this.date)
			},
		},
		mounted(){
			this.get_conciliados();
		},
		methods: {
			...mapActions([
				'getConciliados',
				'getPosiblesConciliados',
				'toggleLoader'
			]),
			async get_conciliados() {
				this.toggleLoader(true);

				await this.getConciliados().then( res => {
					this.toggleLoader(false);
					console.log(res);
					if(res.errors && res.errors.length > 0) {
						this.errors.push(res.error);
					} else {
						if(typeof res.data == 'string') {
							this.tableMessage = res.data;
						} else if(res.data.length > 0) {
							this.datos = res.data;
						} else {
							this.tableMessage = 'No se encontraron reportes';
						}
					}
				})
			},
			async get_posibles_conciliados() {
				this.toggleLoader(true);

				await this.getPosiblesConciliados().then( res => {
					this.toggleLoader(false);
					console.log(res);
					if(res.errors && res.errors.length > 0) {
						this.errors.push(res.error);
					} else {
						if(typeof res.data == 'string') {
							this.tableMessage = res.data;
						} else if(res.data.length > 0) {
							this.datosPosibles = res.data;
						} else {
							this.tableMessage = 'No se encontraron reportes';
						}
					}
				})
			},
		},
	}
</script>